import { useEffect } from 'react'
import { Content, Text } from './styled'

const NotFound = () => {
  useEffect(() => {
    document.title = '404 - Not Found!'
  })

  return (
    <Content>
      <Text>404 - Not Found!</Text>
    </Content>
  )
}

export default NotFound
