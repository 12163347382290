import { useEffect, useCallback, useMemo } from 'react'
import {
  Content,
  Header,
  Title,
  Body,
  Section,
  SectionTitle,
  SectionContent,
  List,
  ListItem,
  Footer,
  Action,
  ActionDescription,
  ActionButtons,
  ActionLink,
  Text,
  Bold,
  Highlighted,
} from './styled'
import { useCandidate } from '../hooks'
import { analytics } from '../services'
import { getCalendlyURL } from '../utils'

const CoremakerJavaDeveloper = () => {
  const { id, name, linkedInURL } = useCandidate()

  useEffect(() => {
    document.title = 'Java Developer @Coremaker'
  })

  useEffect(() => {
    if (!id) return
    analytics.identify(String(id), { name })
    analytics.page()
  }, [id, name])

  const calendlyURL = useMemo(() => {
    const baseURL = 'https://calendly.com/ionut-covacel/30min'
    return getCalendlyURL(baseURL, name, linkedInURL)
  }, [name, linkedInURL])

  const trackSchedule = useCallback(() => {
    analytics.track('scheduleCall', {
      client: 'Coremaker',
      position: 'Java Developer',
    })
  }, [])

  return (
    <Content>
      <Header>
        <Title>{name ? `Hi ${name}!` : 'Hi!'}</Title>
        <Text>
          We are looking for an experienced <Bold>Java Developer</Bold> to help
          us developing an innovation project for one of the biggest remittance
          companies in the UK.
        </Text>
      </Header>

      <Body>
        <Section>
          <SectionTitle>Contract</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Full-time</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Location</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Remote</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Who you are</SectionTitle>
          <SectionContent>
            <Text>
              You are experienced building Java services with Spring, Micronaut
              or Quarkus.
            </Text>

            <Text>
              You have a passion for innovation and delivering business value.
            </Text>

            <Text>You are collborative, and keen to share ideas.</Text>
            <Text>You have excellent communication and teamwork skills.</Text>
            <Text>You speak English fluently.</Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Requirements</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                <Highlighted>
                  A strong Java development experience, including Java 8 / 11.
                </Highlighted>
              </ListItem>
              <ListItem>
                <Highlighted>
                  Experience with the Spring Boot framework, and developing
                  APIs.
                </Highlighted>
              </ListItem>
              <ListItem>
                <Highlighted>
                  Familiarity with relational databases (e.g. MySQL, Postgres).
                </Highlighted>
              </ListItem>
              <ListItem>
                <Highlighted>Experience with Kafka is a plus.</Highlighted>
              </ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Responsibilities</SectionTitle>
          <SectionContent>
            <List>
              <Text>
                Develop and maintain well-functioning Java backend services.
              </Text>
              <Text>
                Contribute to the design of the architecture and the
                implementation of the system using microservices.
              </Text>
              <Text>
                Research for best practices and technologies to implement
                various aspects of the microservices (API schema, monitoring,
                metrics, events bus, etc).
              </Text>
              <Text>
                Write automated tests to ensure the good functioning and
                efficiency of the software (Unit testing, Integration testing).
              </Text>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Benefits</SectionTitle>
          <SectionContent>
            <Text>Working with an experienced team of developers.</Text>
            <Text>Room to learn and progress.</Text>
            <Text>Technical training.</Text>
            <Text>15,000-30,000 RON per month.</Text>
            <Text>Working for an exciting innovative company.</Text>
          </SectionContent>
        </Section>
      </Body>

      <Footer>
        <Action>
          <ActionDescription>
            <Text>
              If you think the project sounds interesting and you are a good fit
              for this role, we would love to have a chat with you.
            </Text>
          </ActionDescription>
          <ActionButtons>
            <ActionLink href={calendlyURL} onClick={trackSchedule}>
              Schedule a call
            </ActionLink>
          </ActionButtons>
        </Action>
      </Footer>
    </Content>
  )
}

export default CoremakerJavaDeveloper
