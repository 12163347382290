import { useMemo } from 'react'
import { useParams } from 'react-router'
import { decode } from '../utils'

const useCandidate = () => {
  const { code } = useParams()

  return useMemo(() => {
    try {
      return decode(code)
    } catch (error) {
      return {}
    }
  }, [code])
}

export default useCandidate
