import { useEffect, useCallback, useMemo } from 'react'
import {
  Content,
  Header,
  Title,
  Body,
  Section,
  SectionTitle,
  SectionContent,
  List,
  ListItem,
  Footer,
  Action,
  ActionDescription,
  ActionButtons,
  ActionLink,
  Text,
  Bold,
  Highlighted,
} from './styled'
import { useCandidate } from '../hooks'
import { analytics } from '../services'
import { getCalendlyURL } from '../utils'

const CoremakerGoDeveloper = () => {
  const { id, name, linkedInURL } = useCandidate()

  useEffect(() => {
    document.title = 'Go Developer @Coremaker'
  })

  useEffect(() => {
    if (!id) return
    analytics.identify(String(id), { name })
    analytics.page()
  }, [id, name])

  const calendlyURL = useMemo(() => {
    const baseURL = 'https://calendly.com/ionut-covacel/30min'
    return getCalendlyURL(baseURL, name, linkedInURL)
  }, [name, linkedInURL])

  const trackSchedule = useCallback(() => {
    analytics.track('scheduleCall', {
      client: 'Coremaker',
      position: 'Go Developer',
    })
  }, [])

  return (
    <Content>
      <Header>
        <Title>{name ? `Hi ${name}!` : 'Hi!'}</Title>
        <Text>
          We are looking for a talented <Bold>Go Developer</Bold> to work with
          us on the applications of a card payments startup based in Stockholm.
        </Text>

        <Text>
          In this role, you will be working on the existing B2C microservices
          but also on building the backend of a greenfield B2B product.
        </Text>
      </Header>

      <Body>
        <Section>
          <SectionTitle>Contract</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Full-time</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Location</SectionTitle>
          <SectionContent>
            <Text>
              <Text>
                <Highlighted>Remote</Highlighted>
              </Text>

              <Text margin="none">
                The position is completely remote and you'll be part of a small
                team where you will be reporting directly to the lead software
                developer.
              </Text>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Working with us</SectionTitle>
          <SectionContent>
            <Text>
              At Coremaker we've created a friendly and collaborative team that
              always strives to help one another. Working with us will give you
              the chance to work with the best technologies with some of the
              most innovative businesses within the industry.
            </Text>

            <Text>
              Our close knit community will provide you also with ample
              opportunity to learn, develop and most of all, love what you do.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Requirements</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                <Highlighted>Go</Highlighted> development experience or desire
                to learn.
              </ListItem>

              <ListItem>
                Solid experience writing{' '}
                <Highlighted>backend services</Highlighted> and{' '}
                <Highlighted>APIs</Highlighted>.
              </ListItem>

              <ListItem>
                Familiarity with <Highlighted>SQL</Highlighted> databases (e.g.
                Postgres, MySQL).
              </ListItem>

              <ListItem>Collaborative, keen to learn and share ideas.</ListItem>
              <ListItem>Great attention to details.</ListItem>
              <ListItem>Confident English speaking.</ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Responsibilities</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                Develop and maintain well-functioning Go backend services.
              </ListItem>

              <ListItem>Design and implement effective APIs.</ListItem>

              <ListItem>
                Contribute to the design of the architecture and the
                implementation of the system using microservices.
              </ListItem>

              <ListItem>
                Research for best practices and technologies to implement
                various aspects of the microservices (API schema, monitoring,
                metrics etc).
              </ListItem>

              <ListItem>
                Write automated tests to ensure the good functioning and
                efficiency of the software (Unit testing, Integration testing).
              </ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Benefits</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                Working with an experienced team of developers.
              </ListItem>
              <ListItem>Room to learn and progress.</ListItem>
              <ListItem>Technical trainings.</ListItem>
              <ListItem>Remote work.</ListItem>
              <ListItem>Working for an exciting, innovative company.</ListItem>
              <ListItem>Possibility to work with PFA/SRL.</ListItem>
              <ListItem>
                <Bold>14,000 - 23,000 RON</Bold> gross per month.
              </ListItem>
            </List>
          </SectionContent>
        </Section>
      </Body>

      <Footer>
        <Action>
          <ActionDescription>
            <Text>
              If you think the project sounds interesting and you are a good fit
              for this role, we would love to have a chat with you.
            </Text>
          </ActionDescription>
          <ActionButtons>
            <ActionLink href={calendlyURL} onClick={trackSchedule}>
              Schedule a call
            </ActionLink>
          </ActionButtons>
        </Action>
      </Footer>
    </Content>
  )
}

export default CoremakerGoDeveloper
