import styled, { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { SCREEN_SIZES } from '../constants'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
      box-sizing: border-box;
  }
 
  body {
      margin: 0;
      background-color: #f2f3f4;
      font-family: "Open Sans", sans-serif;
      color: #000;
  }
`

export const Content = styled.div`
  width: min(850px, 100vw - 80px);
  margin: 40px auto;
  padding: 60px 40px;
  border-radius: 4px;
  background-color: #fff;

  @media (max-width: ${SCREEN_SIZES.MEDIUM}px) {
    width: calc(100vw - 40px);
    margin: 20px auto;
    padding: 30px 20px;
  }

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    width: calc(100vw - 30px);
    margin: 15px auto;
    padding: 20px 20px;
  }
`

export const Header = styled.div`
  margin-bottom: 30px;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  margin: 0 0 15px;
  font-size: 32px;

  @media (max-width: ${SCREEN_SIZES.MEDIUM}px) {
    font-size: 28px;
  }
`

export const Body = styled.div`
  margin-bottom: 30px;
`

export const Section = styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #f2f3f4;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    flex-direction: column;
  }
`

export const SectionTitle = styled.div`
  font-weight: 700;
  width: 25%;
  font-size: 16px;

  @media (max-width: ${SCREEN_SIZES.MEDIUM}px) {
    font-size: 15px;
  }

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const SectionContent = styled.div`
  width: 75%;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    width: 100%;
  }
`

export const ListItem = styled.li`
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 16px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: ${SCREEN_SIZES.MEDIUM}px) {
    font-size: 15px;
  }
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & & {
    margin: 10px 0 0;
  }

  ${props =>
    props.compact &&
    css`
      ${ListItem} {
        margin-bottom: 10px;
      }
    `}
`

export const Text = styled.div`
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 16px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: ${SCREEN_SIZES.MEDIUM}px) {
    font-size: 15px;
  }

  ${props =>
    props.margin &&
    css`
      margin-bottom: ${{ none: 0, small: '6px' }[props.margin]};
    `}
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Italic = styled.span`
  font-style: italic;
`

export const Link = styled.a`
  text-decoration: underline;
  font-weight: 700;
  color: #466ae9;
`

export const Highlighted = styled.span`
  display: inline-block;
  margin: 0 2px;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #466ae9;
  font-weight: 700;
  color: #fff;
`

export const Footer = styled.div``

export const Action = styled.div`
  display: flex;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    flex-direction: column;
  }
`

export const ActionDescription = styled.div`
  width: 75%;
  padding-right: 20px;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const ActionButtons = styled.div`
  width: 25%;
  text-align: right;

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    width: 100%;
  }
`

export const ActionLink = styled.a`
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  line-height: 46px;
  background: #466ae9;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;

  &:hover {
    box-shadow: 0 0 4px #466ae9;
  }

  &:active {
    box-shadow: none;
  }

  @media (max-width: ${SCREEN_SIZES.SMALL}px) {
    font-size: 13px;
  }
`
