import Analytics from 'analytics'
import mixpanelPlugin from '@analytics/mixpanel'
import { MIXPANEL_TOKEN } from '../constants'

const analytics = Analytics({
  app: 'devs.careers',
  plugins: [
    mixpanelPlugin({
      token: MIXPANEL_TOKEN,
    }),
  ],
})

export default analytics
