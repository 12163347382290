import { useEffect, useCallback } from 'react'
import {
  Content,
  Header,
  Title,
  Body,
  Section,
  SectionTitle,
  SectionContent,
  List,
  ListItem,
  Footer,
  Action,
  ActionDescription,
  ActionButtons,
  ActionLink,
  Text,
  Bold,
  Highlighted,
} from './styled'
import { useCandidate } from '../hooks'
import { analytics } from '../services'

const CoremakerReactDeveloper = () => {
  const { id, name } = useCandidate()

  useEffect(() => {
    document.title = 'React Developer @Coremaker'
  })

  useEffect(() => {
    if (!id) return
    analytics.identify(String(id), { name })
    analytics.page()
  }, [id, name])

  const trackSchedule = useCallback(() => {
    analytics.track('scheduleCall', {
      client: 'Coremaker',
      position: 'React Developer',
    })
  }, [])

  return (
    <Content>
      <Header>
        <Title>{name ? `Hi ${name}!` : 'Hi!'}</Title>
        <Text>
          We are looking for a talented <Bold>React Developer</Bold> to work
          with us on the applications of a card payments startup based in
          Stockholm.
        </Text>

        <Text>
          In this role, you will be working on building a greenfield B2B
          product. You will be responsible for the full software development
          life cycle from solution design, implementation to production release.
        </Text>
      </Header>

      <Body>
        <Section>
          <SectionTitle>Contract</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Full-time</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Location</SectionTitle>
          <SectionContent>
            <Text>
              <Text>
                <Highlighted>Remote</Highlighted>
              </Text>

              <Text margin="none">
                The position is completely remote and you'll be part of a small
                team where you will be reporting directly to the lead software
                developer.
              </Text>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Working with us</SectionTitle>
          <SectionContent>
            <Text>
              At Coremaker we've created a friendly and collaborative team that
              always strives to help one another. Working with us will give you
              the chance to work with the best technologies with some of the
              most innovative businesses within the industry.
            </Text>

            <Text>
              Our close knit community will provide you also with ample
              opportunity to learn, develop and most of all, love what you do.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Requirements</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                <Highlighted>3+ years</Highlighted> of experience with{' '}
                <Highlighted>React</Highlighted>.
              </ListItem>

              <ListItem>
                Extensive knowledge of <Highlighted>JavaScript</Highlighted>,{' '}
                <Highlighted>HTML</Highlighted>,<Highlighted>CSS</Highlighted> ,
                knowledge of <Highlighted>Redux</Highlighted>.
              </ListItem>

              <ListItem>
                Experience with <Highlighted>React Native</Highlighted> or
                desire to learn.
              </ListItem>

              <ListItem>
                Knowledge of software design patterns, principles and best
                practices.
              </ListItem>

              <ListItem>
                Proven experience developing web or mobile applications using
                React/React Native.
              </ListItem>

              <ListItem>Excellent communication and teamwork skills.</ListItem>
              <ListItem>Great attention to details.</ListItem>
              <ListItem>Confident English speaking.</ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Responsibilities</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                Build the web application for a new B2B product.
              </ListItem>

              <ListItem>
                Implement new functionalities according to client's
                specifications.
              </ListItem>

              <ListItem>
                Develop and manage a well-functioning web application.
              </ListItem>

              <ListItem>Write effective code.</ListItem>

              <ListItem>
                Test software to ensure responsiveness and efficiency.
              </ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Benefits</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                Working with an experienced team of developers.
              </ListItem>
              <ListItem>Room to learn and progress.</ListItem>
              <ListItem>Remote work.</ListItem>
              <ListItem>Working for an exciting, innovative company.</ListItem>
              <ListItem>Possibility to work with PFA/SRL.</ListItem>
              <ListItem>
                <Bold>14,000 - 23,000 RON</Bold> gross per month.
              </ListItem>
            </List>
          </SectionContent>
        </Section>
      </Body>

      <Footer>
        <Action>
          <ActionDescription>
            <Text>
              If you think the project sounds interesting and you are a good fit
              for this role, we would love to have a chat with you.
            </Text>
          </ActionDescription>
          <ActionButtons>
            <ActionLink
              href="https://calendly.com/ionut-covacel/30min"
              onClick={trackSchedule}
            >
              Schedule a call
            </ActionLink>
          </ActionButtons>
        </Action>
      </Footer>
    </Content>
  )
}

export default CoremakerReactDeveloper
