import { useEffect, useCallback, useMemo } from 'react'
import {
  Content,
  Header,
  Title,
  Body,
  Section,
  SectionTitle,
  SectionContent,
  List,
  ListItem,
  Footer,
  Action,
  ActionDescription,
  ActionButtons,
  ActionLink,
  Text,
  Bold,
  Highlighted,
  Link,
} from './styled'
import { useCandidate } from '../hooks'
import { analytics } from '../services'
import { getCalendlyURL } from '../utils'

const FizzBoxRubyOnRailsDeveloper = () => {
  const { id, name, linkedInURL } = useCandidate()

  useEffect(() => {
    document.title = 'Ruby on Rails Developer @FizzBox'
  })

  useEffect(() => {
    if (!id) return
    analytics.identify(String(id), { name })
    analytics.page()
  }, [id, name])

  const calendlyURL = useMemo(() => {
    const baseURL = 'https://calendly.com/patrickheath/dev-interview'
    return getCalendlyURL(baseURL, name, linkedInURL)
  }, [name, linkedInURL])

  const trackSchedule = useCallback(() => {
    analytics.track('scheduleCall', {
      client: 'FizzBox',
      position: 'Ruby on Rails Developer',
    })
  }, [])

  return (
    <Content>
      <Header>
        <Title>{name ? `Hi ${name}!` : 'Hi!'}</Title>
        <Text>
          We are looking for an experienced <Bold>Ruby on Rails Developer</Bold>
          &nbsp; to help us developing an online marketplace that connects
          customers with thousands of exciting experiences.
        </Text>
      </Header>

      <Body>
        <Section>
          <SectionTitle>Contract</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Full-time</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Location</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Remote</Highlighted>
            </Text>

            <Text>
              We're a very friendly bunch so you will feel like one of the team
              however far away you are.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Requirements</SectionTitle>
          <SectionContent>
            <List>
              <ListItem>
                You are an experienced <Highlighted>Ruby on Rails</Highlighted>
                developer with <Highlighted>3+ years</Highlighted> experience.
              </ListItem>

              <ListItem>
                You are proficient in writing unit and integration tests
                in&nbsp;
                <Highlighted>RSpec</Highlighted>.
              </ListItem>

              <ListItem>
                You are experienced in working with&nbsp;
                <Highlighted>SQL</Highlighted> databases.
              </ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Nice to have skills</SectionTitle>
          <SectionContent>
            <Text>
              While not required, it is considered a bonus if you are
              experienced with some of the following:
            </Text>

            <List>
              <ListItem>
                Development of responsive applications (HTML, CSS).
              </ListItem>

              <ListItem>Development of web applications with React.</ListItem>

              <ListItem>CI/CD and deployment workflows.</ListItem>

              <ListItem>
                Experience with AWS and administration of Linux servers.
              </ListItem>

              <ListItem>Experience with high traffic applications.</ListItem>

              <ListItem>
                Experience of line management and implementing team
                infrastructures.
              </ListItem>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Responsibilities</SectionTitle>
          <SectionContent>
            <List>
              <Text>Adding new features to the Fizzbox platform.</Text>
              <Text>
                Making UI improvements to the Fizzbox website, customer login
                and experience provider platform.
              </Text>
              <Text>Leading key projects.</Text>
              <Text>Maximising the efficiency of internal workflows.</Text>
              <Text>Implementation of internal technology and process.</Text>
              <Text>
                Collaboration with other departments to achieve business goals.
              </Text>
            </List>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>About the project</SectionTitle>
          <SectionContent>
            <Text>
              <Link href="https://www.fizzbox.com/" target="_blank">
                Fizzbox
              </Link>
              &nbsp;is an online marketplace that connects customers with
              thousands of exciting experiences. Our platform enables the
              customer to choose, book and pay with ease, and also helps
              experience providers to manage their bookings and increase
              conversion.
            </Text>

            <Text>
              We work with 400+ providers, and already list over 2,000
              experiences - but our growth plans are ambitious! We want to make
              Fizzbox a global household name. Within a couple of years we are
              confident that Fizzbox will be THE place that people go to book
              their experiences, whether it's for family celebrations, partying
              with friends, days out with loved ones, or corporate team
              connection events.
            </Text>

            <Text>
              The key to achieving this is market leading technology. So, we
              need your help to supercharge the Fizzbox platform and user
              journey to the next level - and beyond!
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Salary</SectionTitle>
          <SectionContent>
            <Text>
              <Bold>€6000/month</Bold>
            </Text>
          </SectionContent>
        </Section>
      </Body>

      <Footer>
        <Action>
          <ActionDescription>
            <Text>
              If you think the project sounds interesting and you are a good fit
              for this role, we would love to have a chat with you.
            </Text>
          </ActionDescription>
          <ActionButtons>
            <ActionLink href={calendlyURL} onClick={trackSchedule}>
              Let's have a chat
            </ActionLink>
          </ActionButtons>
        </Action>
      </Footer>
    </Content>
  )
}

export default FizzBoxRubyOnRailsDeveloper
