import { Routes, Route } from 'react-router-dom'
import {
  CoremakerJavaDeveloper,
  CoremakerDevopsEngineer,
  CoremakerReactDeveloper,
  CoremakerReactNativeDeveloper,
  CoremakerGoDeveloper,
  FizzBoxRubyOnRailsDeveloper,
  LiveTechGamesSeniorGameDeveloper,
  LiveTechGamesGameDeveloper,
  LiveTechGamesSeniorGameArtist,
  LiveTechGamesGameArtist,
  LiveTechGamesGameTester,
  NotFound,
} from './components'

const App = () => (
  <Routes>
    <Route
      exact
      path="/coremaker/java-developer/:code"
      element={<CoremakerJavaDeveloper />}
    />

    <Route
      exact
      path="/coremaker/devops-engineer/:code"
      element={<CoremakerDevopsEngineer />}
    />

    <Route
      exact
      path="/coremaker/react-developer/:code"
      element={<CoremakerReactDeveloper />}
    />

    <Route
      exact
      path="/coremaker/react-native-developer/:code"
      element={<CoremakerReactNativeDeveloper />}
    />

    <Route
      exact
      path="/coremaker/go-developer/:code"
      element={<CoremakerGoDeveloper />}
    />

    <Route
      exact
      path="/fizzbox/ruby-on-rails-developer/:code"
      element={<FizzBoxRubyOnRailsDeveloper />}
    />

    <Route
      exact
      path="/live-tech-games/senior-game-developer/:code"
      element={<LiveTechGamesSeniorGameDeveloper />}
    />

    <Route
      exact
      path="/live-tech-games/game-developer/:code"
      element={<LiveTechGamesGameDeveloper />}
    />

    <Route
      exact
      path="/live-tech-games/senior-game-artist/:code"
      element={<LiveTechGamesSeniorGameArtist />}
    />

    <Route
      exact
      path="/live-tech-games/game-artist/:code"
      element={<LiveTechGamesGameArtist />}
    />

    <Route
      exact
      path="/live-tech-games/game-tester/:code"
      element={<LiveTechGamesGameTester />}
    />

    <Route path="/*" element={<NotFound />} />
  </Routes>
)

export default App
