import { useEffect, useCallback, useMemo } from 'react'
import {
  Content,
  Header,
  Title,
  Body,
  Section,
  SectionTitle,
  SectionContent,
  Footer,
  Action,
  ActionDescription,
  ActionButtons,
  ActionLink,
  Text,
  Bold,
  Italic,
  Highlighted,
  Link,
} from './styled'
import { useCandidate } from '../hooks'
import { analytics } from '../services'
import { getCalendlyURL } from '../utils'

const LiveTechGamesGameArtist = () => {
  const { id, name, linkedInURL } = useCandidate()

  useEffect(() => {
    document.title = 'Game Artist @LiveTechGames'
  })

  useEffect(() => {
    if (!id) return
    analytics.identify(String(id), { name })
    analytics.page()
  }, [id, name])

  const calendlyURL = useMemo(() => {
    const baseURL = 'https://calendly.com/ltg-jobs/motivations-interview'
    return getCalendlyURL(baseURL, name, linkedInURL)
  }, [name, linkedInURL])

  const trackSchedule = useCallback(() => {
    analytics.track('scheduleCall', {
      client: 'LiveTechGames',
      position: 'Game Artist',
    })
  }, [])

  return (
    <Content>
      <Header>
        <Title>{name ? `Hi ${name}!` : 'Hi!'}</Title>
        <Text>
          We are looking for an experienced <Bold>Game Artist</Bold>
          &nbsp; to join our team.
        </Text>
      </Header>

      <Body>
        <Section>
          <SectionTitle>Contract</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>Full-time</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Location</SectionTitle>
          <SectionContent>
            <Text>
              <Highlighted>On site</Highlighted> or&nbsp;
              <Highlighted>Remote</Highlighted>
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Who you are</SectionTitle>
          <SectionContent>
            <Text>
              You are an experienced mobile game artist with&nbsp;
              <Highlighted>2+ years</Highlighted> experience.
            </Text>

            <Text>
              You are proficient in creating&nbsp;
              <Highlighted>game artwork</Highlighted>,&nbsp;
              <Highlighted>wireframing</Highlighted>,&nbsp;
              <Highlighted>animations</Highlighted>,&nbsp;
              <Highlighted>shading</Highlighted> and importing assets in Unity.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Responsibilities</SectionTitle>
          <SectionContent>
            <Text>
              You will work alongside 1 other awesome person in our design team,
              our Lead UI/UX Artist to start with.
            </Text>

            <Text>
              You will work alongside a <Italic>Lead Game Developer</Italic>
              &nbsp; and <Italic>Mid Game Developer</Italic>. You will be
              the&nbsp;<Italic>Senior</Italic>.
            </Text>

            <Text>
              Your most important role will be creating the artwork for our
              mobile games.
            </Text>

            <Text>
              You will work very closely with our game designer and producer,
              taking their designs and use-cases and transforming them into
              mobile app wire-frames and then final app screens.
            </Text>

            <Text>
              Your work will involve creating: concepts, icons, backgrounds,
              characters, GUI elements, environments, gameplay features,
              animations, audio, assets, and so on.
            </Text>

            <Text>
              As a rapidly growing start-up, there are guaranteed to be other
              opportunities that arise where we will require your artistic
              prowess. This may include items such as: websites, pitch decks,
              brand integration for sponsors in our games, potential social
              media artwork, team merchandise, office artwork, and so on.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>The project</SectionTitle>
          <SectionContent>
            <Text>
              We build unique live tournament games. If you imagine, tournaments
              are scheduled in advance to start at a set time, e.g. 15 minutes
              after every world cup game. ITV then advertise the tournament on
              the TV. Users download the free app and they join the tournament.
              All players are matched up in a 1vs1 match at random, and you can
              see who your opponent is. There is then a simple mini game, the
              winner of the 1v1 match goes through to the next round, the loser
              is eliminated. This tournament continues until there is only 1
              person remaining. They win £1,000 for free.
            </Text>

            <Text>
              We have invested millions in building our live tech gaming
              platform that enables these tournaments to run. We now need client
              side developers to build different game mechanics on top of the
              platform.
            </Text>

            <Text>
              We are expecting each gaming project to take 3-6 months depending
              on complexity, re-using our platform.
            </Text>

            <Text>
              Each project will have a Producer, Game Designer, Artist, Client
              Developers, Server Developers and QA.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>The team</SectionTitle>
          <SectionContent>
            <Text>
              Our team is a great mixture of kind, non-ego, intelligent people
              with lots of different nationalities.
            </Text>

            <Text>
              We adhere to strong company values: quality execution, authentic
              passion, apply empathy, purposeful improvement and the tortoise
              and the hare.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>How we work</SectionTitle>
          <SectionContent>
            <Text>We work 9 AM - 5:30 PM, Monday-Friday.</Text>

            <Text>We have a daily standup ap 9:30 AM.</Text>

            <Text>
              We use Microsoft DevOps (similar to Jira) to track epics and
              features.
            </Text>

            <Text>
              Most people work hybrid: Monday & Friday remote, Tuesday-Thursday
              in the office. You can also work fully remote.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Salary</SectionTitle>
          <SectionContent>
            <Text>
              <Bold>£30,000 - 40,000 per year</Bold>
            </Text>

            <Text>
              Up to <Bold>20%</Bold> end of year bonuses (10% is cash, 10% is
              share options).
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Other benefits</SectionTitle>
          <SectionContent>
            <Text margin="small">
              <Bold>Impact</Bold>
            </Text>

            <Text>
              We're a small, newly forming, rapidly growing team. You will have
              a massive impact on the overall studio and each game. Your opinion
              matters and you'll have huge variety. There's not many of us!
            </Text>

            <Text margin="small">
              <Bold>Fame</Bold>
            </Text>

            <Text>
              LTG has strategic partnership with ITV, who will advertise our
              games on their biggest shows to millions of people. Your games
              will be played and seen.
            </Text>

            <Text margin="small">
              <Bold>Supportive culture</Bold>
            </Text>

            <Text>
              We're creating a very close working, empathetic team who get the
              best out of each other, supporting each other personally and
              professionally.
            </Text>

            <Text margin="small">
              <Bold>Quarterly offsites</Bold>
            </Text>

            <Text>
              Every 3 month's the whole company gets out of the office/work for
              a day activity with each other to blow off some steam & have a
              laugh!
            </Text>

            <Text margin="small">
              <Bold>New projects</Bold>
            </Text>

            <Text>
              We're not working on one big archaic game and asking you to
              support it. We're asking you to work with us on creating multiple
              different gaming projects and be involved across the gaming
              lifecycle.
            </Text>

            <Text margin="small">
              <Bold>Hybrid working</Bold>
            </Text>

            <Text>
              We have a central London modern office for our HQ - where we all
              sit Tues, Wed, Thurs, and WFH Mon & Fri. Ideally, you'll join us.
              For the right person, we're also open to full remote with
              occasional travel to HQ.
            </Text>

            <Text margin="small">
              <Bold>Ambitious leaders</Bold>
            </Text>

            <Text>
              LTG has an incredibly strong leadership team and board of
              directors who have huge plans for the studio, in the UK &
              globally. You'll be part of something epic.
            </Text>

            <Text margin="small">
              <Bold>All the usuals</Bold>
            </Text>

            <Text>
              All the equipment, licenses and software you need to do your best
              work, pension plans, health plans, 25 days annual leave + public
              holidays.
            </Text>
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>More about us</SectionTitle>
          <SectionContent>
            <Text>
              <Link target="_blank" href="https://livetechgames.com">
                livetechgames.com
              </Link>
            </Text>

            <Text>
              <Link
                target="_blank"
                href="https://customers.microsoft.com/en-gb/story/1430177684349408279-live-tech-games"
              >
                microsoft.com
              </Link>
            </Text>

            <Text>
              <Link
                target="_blank"
                href="https://www.exchangewire.com/blog/2021/09/08/live-entertainment-and-mobile-gaming-qa-with-live-tech-games"
              >
                exchangewire.com
              </Link>
            </Text>

            <Text>
              <Link
                target="_blank"
                href="https://www.pocketgamer.biz/interview/77822/2021-in-review-live-tech-games-samuel-worsley-nathan-moore/"
              >
                pocketgamer.biz
              </Link>
            </Text>
          </SectionContent>
        </Section>
      </Body>

      <Footer>
        <Action>
          <ActionDescription>
            <Text>
              If you think the project sounds interesting and you are a good fit
              for this role, we would love to have a chat with you.
            </Text>
          </ActionDescription>
          <ActionButtons>
            <ActionLink href={calendlyURL} onClick={trackSchedule}>
              Schedule a call
            </ActionLink>
          </ActionButtons>
        </Action>
      </Footer>
    </Content>
  )
}

export default LiveTechGamesGameArtist
